exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-chronik-jsx": () => import("./../../../src/pages/chronik.jsx" /* webpackChunkName: "component---src-pages-chronik-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-leitung-jsx": () => import("./../../../src/pages/leitung.jsx" /* webpackChunkName: "component---src-pages-leitung-jsx" */),
  "component---src-pages-spenden-jsx": () => import("./../../../src/pages/spenden.jsx" /* webpackChunkName: "component---src-pages-spenden-jsx" */),
  "component---src-pages-vorstand-jsx": () => import("./../../../src/pages/vorstand.jsx" /* webpackChunkName: "component---src-pages-vorstand-jsx" */)
}

